import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img src="img/logo-2.png" alt="Logo" />
                    </div>
                  </div>

                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder"></i>
                        </div>
                        <div className="footer-address-info">
                          <p>
                            #58-5-26/4,Pantakaluva Road, Opp.RTC Colony 3rd
                            Road, Patamata,Vijayawada-10.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call"></i>
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:910008 3333">910008 3333</a>
                            <br />
                            <a href="tel:910008 4444">910008 4444</a>
                            <br />
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail"></i>
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="mailto:hemadurga.vja@gmail.com">
                              hemadurga.vja@gmail.com
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="ltn__social-media mt-20">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/Hemadurga-Jewel-County-106408471811550"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Facebook"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/hemadurga_vja?utm_medium=copy_link"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Instagram"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Quick Links</h4>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="/about">About</a>
                      </li>
                      <li>
                        <a href="/project">Project Details</a>
                      </li>
                      <li>
                        <a href="/contact">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12"></div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12"></div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12"></div>
            </div>
          </div>
        </div>
        <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
          <div className="container-fluid ltn__border-top-2">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="ltn__copyright-design clearfix">
                  <p>
                    All Rights Reserved @ Hema Durga Realtors{" "}
                    <span className="current-year"></span>
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12 align-self-center">
                <div className="ltn__copyright-menu text-end">
                  <ul>
                    <li>
                      <a href="#/">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="#/">Claim</a>
                    </li>
                    <li>
                      <a href="#/">Privacy & Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
