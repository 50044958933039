import React, { Fragment } from "react";
import all from "./common/all";

const Contact = () => {
  return (
    <Fragment>
      <div className="body-wrapper">
        <all.Header />
        <all.PageTitle name="Contact Us" img="img/bg/14.jpg" />
        <div className="ltn__contact-address-area mb-90">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/10.png" alt="Icon Imagee" />
                  </div>
                  <h3>Email Address</h3>
                  <p>hemadurga.vja@gmail.com</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/11.png" alt="Icon Imagee" />
                  </div>
                  <h3>Phone Number</h3>
                  <p>
                    910008 3333
                    <br />
                    910008 4444
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/12.png" alt="Icon Imagee" />
                  </div>
                  <h3>Office Address</h3>
                  <p>
                    #58-5-26/4,Pantakaluva Road,
                    <br /> Opp.RTC Colony 3rd Road,
                    <br /> Patamata,Vijayawada-10.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__contact-message-area mb-120 mb--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__form-box contact-form-box box-shadow white-bg">
                  <h4 className="title-2">Contact Us</h4>
                  <form
                    id="contact-form"
                    action="https://tunatheme.com/tf/html/quarter-preview/quarter/mail.php"
                    method="post"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-email ltn__custom-icon">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email address"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select className="nice-select">
                            <option>Select Service Type</option>
                            <option>Property Management </option>
                            <option>Mortgage Service </option>
                            <option>Consulting Service</option>
                            <option>Home Buying</option>
                            <option>Home Selling</option>
                            <option>Escrow Services</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Enter phone number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="message"
                        placeholder="Enter message"
                      ></textarea>
                    </div>
                    <div className="btn-wrapper mt-0">
                      <button
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                    <p className="form-messege mb-0 mt-20"></p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="google-map mb-120">
          <iframe
            title="contactgoogle"
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d61200.341756138216!2d80.74002991033286!3d16.525019268991585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3a35e2d21d847fd3%3A0x387a916d6650126c!2sHemadurga%20jewel%20county%2C%20Kesarapalli%2C%20Andhra%20Pradesh%20521102!3m2!1d16.524927299999998!2d80.77507899999999!4m5!1s0x3a35e2d21d847fd3%3A0x387a916d6650126c!2sHemadurga%20jewel%20county%2C%20GQFG%2BX2F%2C%20Kesarapalli%2C%20Andhra%20Pradesh%20521102!3m2!1d16.524927299999998!2d80.77507899999999!5e0!3m2!1sen!2sin!4v1640883423219!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>

        <all.DownFooter />
        <all.Footer />
      </div>
    </Fragment>
  );
};

export default Contact;
