import React, { Fragment } from "react";

const PageTitle = ({ name, img }) => {
  return (
    <Fragment>
      <div
        className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image"
        data-bs-bg={img}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title">{name}</h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="index.html">
                        <span className="ltn__secondary-color">
                          <i className="fas fa-home"></i>
                        </span>
                        Home
                      </a>
                    </li>
                    <li>{name}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageTitle;
