import React, { Fragment } from "react";
import all from "./common/all";
// import OwlCarousel from "react-owl-carousel";

const About = () => {
  return (
    <Fragment>
      <div className="body-wrapper">
        <all.Header />
        <all.PageTitle name="About Us" img="img/bg/14.jpg" />
        <div className="ltn__about-us-area pt-120--- pb-90 mt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  <img src="img/others/133.jpg" alt="About Us Imagee" />
                  <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                    <div className="ltn__video-img ltn__animation-pulse1"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2--- mb-20">
                    <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                      OUR BACKGROUND & WHEREABOUTS
                    </h6>
                    <h3 className="section-title">
                      Your Gatway To A Happy & Fulfilling Home!<span>.</span>
                    </h3>
                    <p>
                      Hema Durga Jewel County is a luxurious gated community in
                      Vijayawada with flats and apartments containing all round
                      facilities at affordable prices. The project is built and
                      managed by Sri Bandi Ramesh (SHARVANI VENTURES & AVENUES
                      PVT LTD, Hyderabad) and Sri Chalasani Nagasuresh (HIMAJA
                      CONSTRUCTIONS ,Vijayawada) and its associates who are
                      highly regarded in real estate sector all across Andhra
                      Pradesh and Telangana. We utilize all of that expertise to
                      fulfill our responsibility towards our clients by
                      providing them a memorable experience in their transaction
                      which is better ensured when they gain the highest level
                      of customer service. We believe that the experience that
                      our clients undertake must be filled with satisfaction
                      that they could cherish for years to come. We do this in
                      an effort to become one of the top trusted Real Estate
                      teams in the state.
                    </p>
                  </div>
                  <div className="btn-wrapper animated">
                    <a href="/project" className="theme-btn-1 btn btn-effect-1">
                      OUR PROJECTS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__about-us-area pt-120--- pb-90 mt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2--- mb-20">
                    <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                      Vision
                    </h6>
                    <h1 className="section-title">
                      Vision<span>.</span>
                    </h1>
                    <p>
                      Client satisfaction is our utmost priority. We do whatever
                      it takes to make sure that every client who works with a
                      member of the team feels welcomed just like family. We
                      believe in the power of honesty and so we always strive to
                      be truthful to them by taking accountability for our
                      actions.
                    </p>
                  </div>
                  <div className="btn-wrapper animated">
                    <a href="/contact" className="theme-btn-1 btn btn-effect-1">
                      CONTACT US
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  <img src="img/others/133.jpg" alt="About Us Imagee" />
                  <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                    <div className="ltn__video-img ltn__animation-pulse1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    Our Mission
                  </h6>
                  <h1 className="section-title">Our Main Focus</h1>
                </div>
              </div>
            </div>
            <div className="row ltn__custom-gutter--- justify-content-center">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon">
                    <img src="img/icons/icon-img/21.png" alt="#" />
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="#/">Buy a home</a>
                    </h3>
                    <p>
                      over 1 million+ homes for sale available on the website,
                      we can match you with a house you will want to call home.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                  <div className="ltn__feature-icon">
                    <img src="img/icons/icon-img/22.png" alt="#" />
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="#/">Rent a home</a>
                    </h3>
                    <p>
                      over 1 million+ homes for sale available on the website,
                      we can match you with a house you will want to call home.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon">
                    <img src="img/icons/icon-img/23.png" alt="#" />
                  </div>
                  <div className="ltn__feature-info">
                    <h3>
                      <a href="#/">Sell a home</a>
                    </h3>
                    <p>
                      over 1 million+ homes for sale available on the website,
                      we can match you with a house you will want to call home.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="ltn__team-area pt-115 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    Team
                  </h6>
                  <h1 className="section-title">Our Team</h1>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="ltn__team-item ltn__team-item-3---">
                  <div className="team-img">
                    <img src="img/team/member.jpg" alt="Imagee" />
                  </div>
                  <div className="team-info">
                    <h4>
                      <a href="#/">Name</a>
                    </h4>
                    <h6 className="ltn__secondary-color">Designation</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="ltn__team-item ltn__team-item-3---">
                  <div className="team-img">
                    <img src="img/team/member.jpg" alt="Imagee" />
                  </div>
                  <div className="team-info">
                    <h4>
                      <a href="#/">Name</a>
                    </h4>
                    <h6 className="ltn__secondary-color">Designation</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="ltn__team-item ltn__team-item-3---">
                  <div className="team-img">
                    <img src="img/team/member.jpg" alt="Imagee" />
                  </div>
                  <div className="team-info">
                    <h4>
                      <a href="#/">Name</a>
                    </h4>
                    <h6 className="ltn__secondary-color">Designation</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ltn__testimonial-area section-bg-1--- bg-image-top pt-120 pb-70"
          data-bs-bg="img/bg/20.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    Our Testimonial
                  </h6>
                  <h1 className="section-title">Clients Feedback</h1>
                </div>
              </div>
            </div>
            <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
              <div className="col-lg-4">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1"></i>
                      Precious ipsum dolor sit amet consectetur adipisicing
                      elit, sed dos mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad min veniam, quis nostrud Precious
                      ips um dolor sit amet, consecte
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img"></div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Name</h5>
                        <label>Designation</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1"></i>
                      Precious ipsum dolor sit amet consectetur adipisicing
                      elit, sed dos mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad min veniam, quis nostrud Precious
                      ips um dolor sit amet, consecte
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img"></div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Name</h5>
                        <label>Designation</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1"></i>
                      Precious ipsum dolor sit amet consectetur adipisicing
                      elit, sed dos mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad min veniam, quis nostrud Precious
                      ips um dolor sit amet, consecte
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img"></div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Name</h5>
                        <label>Designation</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <all.DownFooter />

        <all.Footer />
      </div>
    </Fragment>
  );
};

export default About;
