import React, { Fragment } from "react";

const DownFooter = () => {
  return (
    <Fragment>
      <div
        className="ltn__call-to-action-area call-to-action-6 before-bg-bottom"
        data-bs-bg="img/1.jpg--"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                <div className="coll-to-info text-color-white">
                  <h1>Take a Look at Our Projects</h1>
                </div>
                <div className="btn-wrapper">
                  <a className="btn btn-effect-3 btn-white" href="/project">
                    Project Details <i className="icon-next"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DownFooter;
