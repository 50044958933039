import React, { Fragment } from "react";
import all from "./common/all";

const Home = () => {
  return (
    <Fragment>
      <div className="body-wrapper">
        <all.Header />

        <div className="ltn__slider-area ltn__slider-3  section-bg-1">
          <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                            Find Your Dream <br /> House By Us
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Welcome to Hemadurga Jewel County, a luxurious
                              gated community accommodating 2 & 3 BHK flats in
                              Vijayawada with various modern amenities.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <a
                              href="/project"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              Project
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img src="img/slider/21.png" alt="#" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal ltn__slide-item-3">
              <div className="ltn__slide-item-inner  text-right text-end">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                            Clubhouse & <br />
                            Commercial Block
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Puts forward multiple recreational options that
                              will engage your body and mind to ensure a healthy
                              lifestyle for you and your loved ones.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <a
                              href="/project"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              OUR PROJECTS
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img slide-img-left">
                        <img src="img/slider/222.png" alt="#" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__car-dealer-form-area mt--65 mt-120 pb-115---">
          <div className="container"></div>
        </div>

        <div className="ltn__about-us-area pt-120 pb-90 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  <img src="img/others/7.png" alt="About Us Imagee" />
                  <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                    <div className="ltn__video-img ltn__animation-pulse1"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2--- mb-20">
                    <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                      About Us
                    </h6>
                    <h1 className="section-title">
                      Your Dream Residential Destination!<span>.</span>
                    </h1>
                    <p>
                      Mission: We aim to offer you an array of wholesome
                      experiences with our modernized luxury apartments.
                    </p>
                  </div>
                  <ul className="ltn__list-item-half clearfix">
                    <li>
                      <i className="flaticon-home-2"></i>
                      Smart Home Design
                    </li>
                    <li>
                      <i className="flaticon-mountain"></i>
                      Beautiful Scene Around
                    </li>
                    <li>
                      <i className="flaticon-heart"></i>
                      Exceptional Lifestyle
                    </li>
                    <li>
                      <i className="flaticon-secure"></i>
                      Complete 24/7 Security
                    </li>
                  </ul>
                  {/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
                    <p>
                      "Enimad minim veniam quis nostrud exercitation <br />
                      llamco laboris. Lorem ipsum dolor sit amet"
                    </p>
                  </div> */}
                  <div className="btn-wrapper animated">
                    <a href="/about" className="theme-btn-1 btn btn-effect-1">
                      ABOUT US
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__video-popup-area ltn__video-popup-margin---">
          <div
            className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image bg-fixed ltn__animation-pulse1"
            data-bs-bg="img/bg/19.jpg"
          >
            <a
              className="ltn__video-icon-2 ltn__video-icon-2-border---"
              href="https://www.youtube.com/embed/eTHdWCgDUbE"
              data-rel="lightcase:myCollection"
            >
              <i className="fa fa-play"></i>
            </a>
          </div>
        </div>

        <div
          className="ltn__category-area section-bg-1-- ltn__primary-bg before-bg-1 bg-image bg-overlay-theme-black-5--0 pt-115 pb-90 d-none"
          data-bs-bg="img/bg/5.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2 text-center">
                  <h1 className="section-title white-color">Top Categories</h1>
                </div>
              </div>
            </div>
            <div className="row ltn__category-slider-active slick-arrow-1">
              <div className="col-12">
                <div className="ltn__category-item ltn__category-item-4 text-center">
                  <div className="ltn__category-item-img">
                    <a href="shop.html">
                      <i className="flaticon-car"></i>
                    </a>
                  </div>
                  <div className="ltn__category-item-name">
                    <h4>
                      <a href="shop.html">Parking Space</a>
                    </h4>
                  </div>
                  <div className="ltn__category-item-btn">
                    <a href="shop.html">
                      <i className="flaticon-right-arrow"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ltn__category-item ltn__category-item-4 text-center">
                  <div className="ltn__category-item-img">
                    <a href="shop.html">
                      <i className="flaticon-car"></i>
                    </a>
                  </div>
                  <div className="ltn__category-item-name">
                    <h4>
                      <a href="shop.html">Parking Space</a>
                    </h4>
                  </div>
                  <div className="ltn__category-item-btn">
                    <a href="shop.html">
                      <i className="flaticon-right-arrow"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ltn__category-item ltn__category-item-4 text-center">
                  <div className="ltn__category-item-img">
                    <a href="shop.html">
                      <i className="flaticon-car"></i>
                    </a>
                  </div>
                  <div className="ltn__category-item-name">
                    <h4>
                      <a href="shop.html">Parking Space</a>
                    </h4>
                  </div>
                  <div className="ltn__category-item-btn">
                    <a href="shop.html">
                      <i className="flaticon-right-arrow"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ltn__category-item ltn__category-item-4 text-center">
                  <div className="ltn__category-item-img">
                    <a href="shop.html">
                      <i className="flaticon-car"></i>
                    </a>
                  </div>
                  <div className="ltn__category-item-name">
                    <h4>
                      <a href="shop.html">Parking Space</a>
                    </h4>
                  </div>
                  <div className="ltn__category-item-btn">
                    <a href="shop.html">
                      <i className="flaticon-right-arrow"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ltn__category-item ltn__category-item-4 text-center">
                  <div className="ltn__category-item-img">
                    <a href="shop.html">
                      <i className="flaticon-car"></i>
                    </a>
                  </div>
                  <div className="ltn__category-item-name">
                    <h4>
                      <a href="shop.html">Parking Space</a>
                    </h4>
                  </div>
                  <div className="ltn__category-item-btn">
                    <a href="shop.html">
                      <i className="flaticon-right-arrow"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ltn__category-item ltn__category-item-4 text-center">
                  <div className="ltn__category-item-img">
                    <a href="shop.html">
                      <i className="flaticon-car"></i>
                    </a>
                  </div>
                  <div className="ltn__category-item-name">
                    <h4>
                      <a href="shop.html">Parking Space</a>
                    </h4>
                  </div>
                  <div className="ltn__category-item-btn">
                    <a href="shop.html">
                      <i className="flaticon-right-arrow"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
          data-bs-bg="img/bg/20.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    Our Testimonial
                  </h6>
                  <h1 className="section-title">Clients Feedback</h1>
                </div>
              </div>
            </div>
            <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
              <div className="col-lg-4">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1"></i>
                      Precious ipsum dolor sit amet consectetur adipisicing
                      elit, sed dos mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad min veniam, quis nostrud Precious
                      ips um dolor sit amet, consecte
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img"></div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Name</h5>
                        <label>Designation</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1"></i>
                      Precious ipsum dolor sit amet consectetur adipisicing
                      elit, sed dos mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad min veniam, quis nostrud Precious
                      ips um dolor sit amet, consecte
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img"></div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Name</h5>
                        <label>Designation</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1"></i>
                      Precious ipsum dolor sit amet consectetur adipisicing
                      elit, sed dos mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad min veniam, quis nostrud Precious
                      ips um dolor sit amet, consecte
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img"></div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Name</h5>
                        <label>Designation</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1"></i>
                      Precious ipsum dolor sit amet consectetur adipisicing
                      elit, sed dos mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad min veniam, quis nostrud Precious
                      ips um dolor sit amet, consecte
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img">
                        <img src="img/testimonial/4.jpg" alt="#/" />
                      </div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>James Carter</h5>
                        <label>Selling Agents</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="google-map mb-120">
          <iframe
            title="contactgoogle"
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d61200.341756138216!2d80.74002991033286!3d16.525019268991585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3a35e2d21d847fd3%3A0x387a916d6650126c!2sHemadurga%20jewel%20county%2C%20Kesarapalli%2C%20Andhra%20Pradesh%20521102!3m2!1d16.524927299999998!2d80.77507899999999!4m5!1s0x3a35e2d21d847fd3%3A0x387a916d6650126c!2sHemadurga%20jewel%20county%2C%20GQFG%2BX2F%2C%20Kesarapalli%2C%20Andhra%20Pradesh%20521102!3m2!1d16.524927299999998!2d80.77507899999999!5e0!3m2!1sen!2sin!4v1640883423219!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>

        <div className="ltn__brand-logo-area ltn__brand-logo-1 section-bg-1 pt-290 pb-110 plr--9 d-none">
          <div className="container-fluid">
            <div className="row ltn__brand-logo-active">
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img src="img/brand-logo/1.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img src="img/brand-logo/2.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img src="img/brand-logo/3.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img src="img/brand-logo/4.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img src="img/brand-logo/5.png" alt="Brand Logo" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <img src="img/brand-logo/3.png" alt="Brand Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ltn__call-to-action-area call-to-action-6 before-bg-bottom"
          data-bs-bg="img/1.jpg--"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                  <div className="coll-to-info text-color-white">
                    <h1>Take a Look at Our Projects</h1>
                  </div>
                  <div className="btn-wrapper">
                    <a className="btn btn-effect-3 btn-white" href="/project">
                      Project Details <i className="icon-next"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <all.Footer />
      </div>
    </Fragment>
  );
};

export default Home;
