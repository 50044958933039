import React, { Fragment } from "react";

const Header = () => {
  return (
    <Fragment>
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
        <div className="ltn__header-top-area section-bg-6 top-area-color-white---">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="mailto:hemadurga.vja@gmail.com?Subject=Flower%20greetings%20to%20you">
                        <i className="icon-mail"></i> hemadurga.vja@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="icon-placeholder"></i>
                        #58-5-26/4,Pantakaluva Road
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li className="d-none">
                        <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                          <ul>
                            <li>
                              <a href="#/" className="dropdown-toggle">
                                <span className="active-currency">English</span>
                              </a>
                              <ul>
                                <li>
                                  <a href="#/">Arabic</a>
                                </li>
                                <li>
                                  <a href="#/">Bengali</a>
                                </li>
                                <li>
                                  <a href="#/">Chinese</a>
                                </li>
                                <li>
                                  <a href="#/">English</a>
                                </li>
                                <li>
                                  <a href="#/">French</a>
                                </li>
                                <li>
                                  <a href="#/">Hindi</a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <div className="ltn__social-media">
                          <ul>
                            <li>
                              <a
                                href="https://www.facebook.com/Hemadurga-Jewel-County-106408471811550"
                                title="Facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            {/* <li>
                              <a href="#/" title="Twitter">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li> */}

                            <li>
                              <a
                                href="https://instagram.com/hemadurga_vja?utm_medium=copy_link"
                                title="Instagram"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                            {/* <li>
                              <a href="#/" title="Dribbble">
                                <i className="fab fa-dribbble"></i>
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </li>
                      <li>
                        {/* <div className="header-top-btn">
                          <a href="add-listing.html">Add Listing</a>
                        </div> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap">
                  <div className="site-logo">
                    <a href="/">
                      <img src="img/logo.png" alt="Logo" />
                    </a>
                  </div>
                  <div className="get-support clearfix d-none">
                    <div className="get-support-icon">
                      <i className="icon-call"></i>
                    </div>
                    <div className="get-support-info">
                      <h6>Get Support</h6>
                      <h4>
                        <a href="tel:+123456789">123-456-789-10</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column">
                <div className="header-menu d-none d-xl-block">
                  <nav>
                    <div className="ltn__main-menu">
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about">About</a>
                        </li>
                        <li>
                          <a href="/project">Project Details</a>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
                <div className="mobile-menu-toggle d-xl-none">
                  <a
                    href="#ltn__utilize-mobile-menu"
                    className="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      ></path>
                      <path d="M300,320 L540,320" id="middle"></path>
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
