import React, { Fragment } from "react";
import all from "./common/all";

const ProjectDetails = () => {
  const handlesubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className="body-wrapper">
        <all.Header />
        <all.PageTitle name="Project Details" img="img/bg/14.jpg" />

        <div className="ltn__img-slider-area mb-90">
          <div className="container-fluid">
            <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
              <div className="col-lg-12">
                <div className="ltn__img-slide-item-4">
                  <a
                    href="img/img-slide/311.jpg"
                    data-rel="lightcase:myCollection"
                  >
                    <img src="img/img-slide/311.jpg" alt="Imageee" />
                  </a>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__img-slide-item-4">
                  <a
                    href="img/img-slide/322.jpg"
                    data-rel="lightcase:myCollection"
                  >
                    <img src="img/img-slide/322.jpg" alt="Imageee" />
                  </a>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ltn__img-slide-item-4">
                  <a
                    href="img/img-slide/333.jpg"
                    data-rel="lightcase:myCollection"
                  >
                    <img src="img/img-slide/333.jpg" alt="Imageee" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__shop-details-area pb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                  <h1>Relish Urban Living With Modern Amenities!</h1>

                  <h4 className="title-2">BREATHTAKING AMENITIES</h4>
                  <p>
                    We are a construction company building a community to bring
                    your dream home to life. As a gated community, we provide
                    our customers with all the necessary security features
                    followed by other advantages. The community is safe and
                    secure, with solar fencing which is all briefly monitored by
                    our able and helpful security officers.
                  </p>
                  <p>
                    Our new gated community is designed to offer you an array of
                    wholesome experiences. With a club house amidst a lush green
                    landscape and a Swimming pool, you get to enjoy a feeling of
                    complete serenity. “Sri Hemadurga Jewelcounty” provides the
                    ideal setting for those who love to enjoy a resort style of
                    living built in and around luxury. Hit the play area with
                    your kids and let them have a fun time with our swing sets,
                    sandbox or small slides.
                  </p>
                  <p>
                    After all, what can be more refreshing than a walk at the
                    break of dawn, in unison with the purest of Nature's
                    elements. Come and live around a remarkably established
                    artistic space with great facilities like: Laminated
                    Flooring and Modular Wardrobes with imported hardware. Large
                    and comfortable living room, exquisitely designed with
                    Living Area openness and airiness.
                  </p>

                  <h4 className="title-2">Property Detail</h4>
                  <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                    <ul>
                      <li>
                        <label>2 BHK:</label> <span>1266 Sqft</span>
                      </li>
                      <li>
                        <label>Living Room: </label> <span>(24x24)</span>
                      </li>
                      <li>
                        <label>Bed Room:</label> <span>(24x24)</span>
                      </li>
                      <li>
                        <label>Kitchen:</label> <span>(24x24)</span>
                      </li>
                      <li>
                        <label>Utility / WashArea :</label>
                        <span>3Ft (height)</span>
                      </li>
                      <li>
                        <label>Wardrobes:</label> <span>230 Sqft</span>
                      </li>
                      <li>
                        <label>Kitchen:</label>
                        <span>
                          2'-0" height above
                          <br /> kitchen platform.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>3 BHK:</label> <span>1663 Sqft </span>
                      </li>
                      <li>
                        <label>Living Room: </label> <span>(24x24)</span>
                      </li>
                      <li>
                        <label>Bed Room:</label> <span>(24x24)</span>
                      </li>
                      <li>
                        <label>Kitchen:</label> <span>(24x24)</span>
                      </li>
                      <li>
                        <label>Utility / WashArea :</label>
                        <span>3Ft (height)</span>
                      </li>
                      <li>
                        <label>Wardrobes:</label> <span>280 Sqft</span>
                      </li>
                      <li>
                        <label>Kitchen:</label>
                        <span>
                          2'-0" height above
                          <br /> kitchen platform.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <h4 className="title-2">Facts and Features</h4>
                  <div className="property-detail-feature-list clearfix mb-45">
                    <ul>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Living Room</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Garage</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Dining Area</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Bedroom</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Bathroom</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Gym Area</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Garden</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed"></i>
                          <div>
                            <h6>Parking</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <h4 className="title-2">From Our Gallery</h4>
                  <div className="ltn__property-details-gallery mb-30">
                    <div className="row">
                      <div className="col-md-6">
                        <a
                          href="img/others/166.jpg"
                          data-rel="lightcase:myCollection"
                        >
                          <img
                            className="mb-30"
                            src="img/others/166.jpg"
                            alt="Imagde"
                          />
                        </a>
                        <a
                          href="img/others/167.jpg"
                          data-rel="lightcase:myCollection"
                        >
                          <img
                            className="mb-30"
                            src="img/others/167.jpg"
                            alt="Imagde"
                          />
                        </a>
                      </div>
                      <div className="col-md-6">
                        <a
                          href="img/others/168.jpg"
                          data-rel="lightcase:myCollection"
                        >
                          <img
                            className="mb-30"
                            src="img/others/168.jpg"
                            alt="Imagde"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <h4 className="title-2 mb-10">Amenities</h4>
                  <div className="property-details-amenities mb-60">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="ltn__menu-widget">
                          <ul>
                            <li>
                              <label className="checkbox-item">
                                Club House
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Jogging Track
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Multipurpose Hall
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Outdoor Games
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Elevators
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Solar Fencing
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                24 Hr. Security With CC Cameras
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Intercom Facility
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="ltn__menu-widget">
                          <ul>
                            <li>
                              <label className="checkbox-item">
                                Children's Play Area
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Gymnasium
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Kid's Pool
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Indoor Games
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Power Backup Generator
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Visitor's Lounge
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Ambulance Facility
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Sewage Treatment Plant
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="ltn__menu-widget">
                          <ul>
                            <li>
                              <label className="checkbox-item">
                                Rain Water Harvesting Pits
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                100% Vaasthu Compliance
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Badminton Court
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Open Green Space
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Entrance Security Plaza
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Water Softener
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                RO Water Treatment Plant for Drinking Water
                                <input type="checkbox" checked="checked" />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h4 className="title-2">Location</h4>
                  <div className="property-details-google-map mb-60">
                    <iframe
                      title="iframe"
                      src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d61200.341756138216!2d80.74002991033286!3d16.525019268991585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3a35e2d21d847fd3%3A0x387a916d6650126c!2sHemadurga%20jewel%20county%2C%20Kesarapalli%2C%20Andhra%20Pradesh%20521102!3m2!1d16.524927299999998!2d80.77507899999999!4m5!1s0x3a35e2d21d847fd3%3A0x387a916d6650126c!2sHemadurga%20jewel%20county%2C%20GQFG%2BX2F%2C%20Kesarapalli%2C%20Andhra%20Pradesh%20521102!3m2!1d16.524927299999998!2d80.77507899999999!5e0!3m2!1sen!2sin!4v1640883423219!5m2!1sen!2sin"
                      width="100%"
                      height="100%"
                      frameborder="0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>

                  {/* <h4 className="title-2">Floor Plans</h4>
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
                      <div className="nav">
                        <a data-bs-toggle="tab" href="#liton_tab_3_1">
                          First Floor
                        </a>
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_tab_3_2"
                        >
                          Second Floor
                        </a>
                        <a
                          data-bs-toggle="tab"
                          href="#liton_tab_3_3"
                          className=""
                        >
                          Third Floor
                        </a>
                        <a
                          data-bs-toggle="tab"
                          href="#liton_tab_3_4"
                          className=""
                        >
                          Top Garden
                        </a>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane fade" id="liton_tab_3_1">
                        <div className="ltn__apartments-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="apartments-plan-img">
                                <img src="img/others/10.png" alt="#" />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>First Floor</h2>
                                <p>
                                  Enimad minim veniam quis nostrud exercitation
                                  ullamco laboris. Lorem ipsum dolor sit amet
                                  cons aetetur adipisicing elit sedo eiusmod
                                  tempor.Incididunt labore et dolore magna
                                  aliqua. sed ayd minim veniam.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="product-details-apartments-info-list  section-bg-1">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Total Area</label>
                                          <span>2800 Sq. Ft</span>
                                        </li>
                                        <li>
                                          <label>Bedroom</label>
                                          <span>150 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Belcony/Pets</label>
                                          <span>Allowed</span>
                                        </li>
                                        <li>
                                          <label>Lounge</label>
                                          <span>650 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade active show"
                        id="liton_tab_3_2"
                      >
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="apartments-plan-img">
                                <img src="img/others/10.png" alt="#" />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>Second Floor</h2>
                                <p>
                                  Enimad minim veniam quis nostrud exercitation
                                  ullamco laboris. Lorem ipsum dolor sit amet
                                  cons aetetur adipisicing elit sedo eiusmod
                                  tempor.Incididunt labore et dolore magna
                                  aliqua. sed ayd minim veniam.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="product-details-apartments-info-list  section-bg-1">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Total Area</label>
                                          <span>2800 Sq. Ft</span>
                                        </li>
                                        <li>
                                          <label>Bedroom</label>
                                          <span>150 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Belcony/Pets</label>
                                          <span>Allowed</span>
                                        </li>
                                        <li>
                                          <label>Lounge</label>
                                          <span>650 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_3_3">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="apartments-plan-img">
                                <img src="img/others/10.png" alt="#" />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>Third Floor</h2>
                                <p>
                                  Enimad minim veniam quis nostrud exercitation
                                  ullamco laboris. Lorem ipsum dolor sit amet
                                  cons aetetur adipisicing elit sedo eiusmod
                                  tempor.Incididunt labore et dolore magna
                                  aliqua. sed ayd minim veniam.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="product-details-apartments-info-list  section-bg-1">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Total Area</label>
                                          <span>2800 Sq. Ft</span>
                                        </li>
                                        <li>
                                          <label>Bedroom</label>
                                          <span>150 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Belcony/Pets</label>
                                          <span>Allowed</span>
                                        </li>
                                        <li>
                                          <label>Lounge</label>
                                          <span>650 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_3_4">
                        <div className="ltn__product-tab-content-inner">
                          <div className="row">
                            <div className="col-lg-7">
                              <div className="apartments-plan-img">
                                <img src="img/others/10.png" alt="#" />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>Top Garden</h2>
                                <p>
                                  Enimad minim veniam quis nostrud exercitation
                                  ullamco laboris. Lorem ipsum dolor sit amet
                                  cons aetetur adipisicing elit sedo eiusmod
                                  tempor.Incididunt labore et dolore magna
                                  aliqua. sed ayd minim veniam.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="product-details-apartments-info-list  section-bg-1">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Total Area</label>
                                          <span>2800 Sq. Ft</span>
                                        </li>
                                        <li>
                                          <label>Bedroom</label>
                                          <span>150 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                      <ul>
                                        <li>
                                          <label>Belcony/Pets</label>
                                          <span>Allowed</span>
                                        </li>
                                        <li>
                                          <label>Lounge</label>
                                          <span>650 Sq. Ft</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <h4 className="title-2">Property Video</h4>
                  <div
                    className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60"
                    data-bs-bg="img/others/5.jpg"
                  >
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/eTHdWCgDUbE?autoplay=1&amp;showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                  <div className="widget ltn__form-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      Enquire Now
                    </h4>
                    <form onSubmit={handlesubmit}>
                      <input
                        type="text"
                        name="yourname"
                        placeholder="Your Name*"
                        required
                      />
                      <input
                        type="text"
                        name="youremail"
                        placeholder="Your e-Mail*"
                        required
                      />
                      <textarea
                        name="yourmessage"
                        placeholder="Write Message..."
                        required
                      ></textarea>
                      <button type="submit" className="btn theme-btn-1">
                        Send Messege
                      </button>
                    </form>
                  </div>

                  <div className="widget ltn__social-media-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      Follow us
                    </h4>
                    <div className="ltn__social-media-2">
                      <ul>
                        <li>
                          <a href="#/" title="Facebook">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>

                        <li>
                          <a href="#/" title="Instagram">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="widget ltn__banner-widget d-none">
                    <a href="shop.html">
                      <img src="img/banner/2.jpg" alt="#" />
                    </a>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <all.DownFooter />

        <all.Footer />
      </div>
    </Fragment>
  );
};

export default ProjectDetails;
